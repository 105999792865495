<template>
  <section v-if="data" class="o-map-address">
    <div class="container">
      <h2 class="o-map-address__title">
        {{ data.title }}
      </h2>

      <div class="o-map-address__addresses">
        <Button
          v-for="(buttons, idx) in data.addresses"
          css-class="button-icon-text"
          :selected="activeAddressState === idx"
          @click="activeAddressState = idx"
        >
          {{ buttons.title }}
        </Button>
      </div>

      <template v-for="(address, idx) in data.addresses">
        <div v-show="idx === activeAddressState" class="o-map-address__info">
          <h3 class="o-map-address__subtitle">{{ address.title }}</h3>
          <div class="o-map-address__map">
            <iframe
              v-if="address.mapLink"
              :src="address.mapLink"
              :title="address.title"
              width="100%"
              height="100%"
              loading="lazy"
            ></iframe>
          </div>
          <div v-if="address.mapsExternal.length" class="o-map-address__services">
            <div class="o-map-address__services-title">Открыть адрес на картах</div>
            <div class="o-map-address__services-items">
              <Button
                tag="a"
                :href="externalMap.link"
                v-for="externalMap in address.mapsExternal"
                css-class="button-icon-text"
                icon-pos="right"
                class="o-map-address__service"
                target="_blank"
              >
                {{ externalMap.title }}
                <template #icon>
                  <i class="icon-main-newwin"></i>
                </template>
              </Button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/icecorp-vue-library/Button';

// Composables
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOMapAddresses } from '~/components/O/Map/Addresses/OMapAddresses.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schemas
import schema from '~/components/O/Map/Addresses/OMapAddresses.schema';

defineOptions({
  name: 'OMapAddresses',
});

const props = withDefaults(defineProps<IComponentProps<TOMapAddresses>>(), {
  viewName: 'OMapAddresses',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const activeAddressState = ref(0);
const data = props.data.component;

useComponentSchemaValidator(schema, props);
</script>
